import { Svg, SvgProps } from '@pancakeswap/uikit'

const GradientLogo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="101" height="86" viewBox="0 0 101 86" fill="none" {...props}>
      <path d="M31.2054 0.0282032C36.7255 0.0282032 42.2456 0.0422463 47.7799 0.0141602C48.3917 0.0141602 48.5766 0.140548 48.5624 0.772485C48.5197 4.94327 48.534 9.10002 48.5624 13.2708C48.5624 13.8747 48.3917 14.001 47.8084 14.001C39.514 13.987 31.2196 13.987 22.9252 14.001C19.6387 14.001 17.7181 15.8969 17.7038 19.1829C17.7038 20.166 17.7607 21.149 18.1449 22.0758C18.9558 24.0278 20.6062 24.5895 22.5553 24.6036C26.1832 24.6176 29.8111 24.5755 33.439 24.6457C36.1706 24.7019 38.8453 25.1232 41.4062 26.1483C45.4609 27.7773 47.6519 30.8106 48.2067 34.9814C48.7616 39.1662 48.662 43.3932 48.3632 47.5921C47.908 54.2485 43.4122 59.2197 36.8393 60.7504C35.1605 61.1436 33.4817 61.3121 31.7744 61.3121C23.0532 61.3121 14.332 61.27 5.6108 61.3402C2.93611 61.3542 0.873177 59.4584 0.887405 56.7622C0.901632 55.2455 0.873177 53.7148 0.901632 52.1982C0.95854 49.6564 2.89343 47.8167 5.4543 47.8027C12.3971 47.8027 19.34 47.8027 26.2828 47.8027C26.923 47.8027 27.5774 47.8308 28.2034 47.7325C30.1241 47.4095 31.248 46.2439 31.5895 44.3621C31.8171 43.1544 31.8456 41.9467 31.5326 40.7531C30.9919 38.7309 29.242 37.3827 27.1079 37.3547C24.1487 37.3406 21.1752 37.3687 18.216 37.3406C15.7974 37.3125 13.393 37.13 11.0882 36.2874C5.31203 34.1528 1.69835 30.1927 1.17195 24.0278C0.759361 19.3515 0.631317 14.6189 1.32844 9.95665C2.01135 5.47691 4.42995 2.4717 9.76511 0.926959C11.885 0.309064 14.0617 0.000117095 16.2811 0.0422463C16.6653 0.0422463 17.0352 0.0422463 17.4193 0.0422463C22.0146 0.0282032 26.61 0.0282032 31.2054 0.0282032Z" fill="#34B589" />
      <path d="M82.475 62.7446C87.9382 70.4964 93.3588 78.178 98.7793 85.8595C98.6086 86.0421 98.4094 85.9719 98.2387 85.9719C90.5845 85.9719 82.9303 85.9719 75.2761 85.9859C74.6928 85.9859 74.3656 85.8314 74.0241 85.3399C68.9451 78.1077 63.8518 70.8615 58.73 63.6434C58.289 63.0255 58.0898 62.4076 58.104 61.6493C58.1467 59.3322 58.1894 57.0151 58.104 54.698C58.0186 52.1281 60.1812 50.148 62.7136 50.1621C68.1626 50.2042 73.6115 50.1761 79.0748 50.1761C79.8146 50.1761 80.5401 50.1059 81.2373 49.8812C82.4608 49.488 83.3287 48.6875 83.7555 47.5079C84.5237 45.4155 84.538 43.2669 83.6843 41.2026C82.9018 39.3208 81.2515 38.7029 79.3166 38.6889C73.7396 38.6748 68.1768 38.6889 62.5998 38.6748C60.8498 38.6748 59.5125 37.9165 58.6304 36.4139C58.2463 35.7679 58.1182 35.0658 58.1182 34.3215C58.1182 31.3022 58.1325 28.283 58.104 25.2637C58.104 24.7301 58.2605 24.6037 58.7869 24.6177C67.4085 24.6318 76.0444 24.6318 84.666 24.6318C87.7391 24.6318 90.7694 24.8986 93.6433 26.0922C97.4277 27.651 99.6471 30.4877 100.543 34.3777C100.871 35.796 100.984 37.2424 100.984 38.6889C100.97 42.1996 100.999 45.7104 100.984 49.2212C100.97 51.1451 100.629 53.0268 99.9744 54.8524C99.2203 56.9589 97.5558 58.2087 95.7347 59.29C93.1027 60.8488 90.1861 61.6352 87.1842 62.1267C85.6761 62.3514 84.1396 62.5199 82.475 62.7446Z" fill="#33B589" />
      <path d="M73.8834 0.0283792C81.4522 0.0283792 89.0068 0.0845515 96.5756 0.000293078C98.9089 -0.027793 101.086 1.96632 101 4.45194C100.943 6.0669 101 7.68185 100.986 9.2968C100.972 12.2318 99.1934 13.9872 96.2057 13.9872C91.0555 13.9872 85.9195 13.9872 80.7693 13.9872C80.1006 13.9872 79.4462 13.9872 78.7775 14.0574C76.487 14.2821 74.8366 16.0515 74.8082 18.3265C74.7939 19.7308 74.7939 21.1351 74.8082 22.5394C74.8224 23.0028 74.7086 23.1854 74.1822 23.1854C69.0604 23.1573 63.9387 23.1573 58.8169 23.1854C58.2051 23.1854 58.0629 23.0028 58.0771 22.4411C58.1198 21.2053 58.1055 19.9555 58.0913 18.7197C58.0629 15.8268 56.2702 14.0434 53.3537 14.0012C52.4716 13.9872 51.6038 13.9731 50.7217 14.0012C50.181 14.0293 49.9961 13.9029 49.9961 13.3131C50.0245 9.12829 50.0245 4.94345 49.9961 0.744575C49.9961 0.154767 50.1526 0.0143361 50.7359 0.0143361C58.4754 0.0424223 66.1865 0.0283792 73.8834 0.0283792Z" fill="#33B589" />
      <path d="M43.8613 71.8028C44.7576 71.8028 45.5828 71.8028 46.408 71.8028C48.5847 71.8028 50.7757 71.7466 52.9524 71.8168C55.0011 71.873 56.6088 71.2129 57.7043 69.3452C61.6167 74.8922 65.4865 80.3831 69.3705 85.8739C69.1287 86.0424 68.9153 85.9722 68.7161 85.9722C63.95 85.9722 59.1981 85.9722 54.4321 85.9862C53.9768 85.9862 53.7065 85.8599 53.4504 85.4807C50.3347 81.0431 47.1905 76.6055 44.0605 72.1679C43.9751 72.0977 43.9467 71.9853 43.8613 71.8028Z" fill="#30B489" />
      <path d="M51.0669 85.986C49.2317 85.986 47.5386 85.986 45.8314 85.986C44.0103 85.986 42.1892 85.986 40.3539 86C40.0125 86 39.8133 85.9157 39.5999 85.6208C36.5126 81.2113 33.3969 76.8158 30.3096 72.4204C30.1958 72.2659 29.9255 72.1114 30.0677 71.8867C30.1958 71.6901 30.4661 71.8025 30.6653 71.8025C33.9375 71.8025 37.2098 71.8025 40.4678 71.7884C40.8092 71.7884 41.0938 71.8165 41.3214 72.1395C44.5367 76.7175 47.7663 81.2956 51.0669 85.986Z" fill="#30B489" />
      <path d="M19.2539 71.8021C21.7436 71.8021 24.1623 71.8162 26.5666 71.7881C26.9934 71.7881 27.1357 72.083 27.3207 72.3358C29.0848 74.8214 30.8205 77.321 32.5705 79.8067C33.979 81.8148 35.4017 83.823 36.8101 85.8171C36.6963 86.0699 36.5114 85.9716 36.3691 85.9856C34.1355 85.9997 31.916 85.9856 29.6824 85.9997C29.3551 85.9997 29.1133 85.9435 28.8999 85.6486C25.7272 81.1267 22.5404 76.6189 19.3535 72.1111C19.325 72.0409 19.3108 71.9706 19.2539 71.8021Z" fill="#30B489" />
      <path d="M16.2201 85.9864C14.3706 85.9864 12.6349 85.9864 10.885 85.9864C10.6147 85.9864 10.5293 85.7758 10.4155 85.6073C7.71234 81.7735 5.00919 77.9257 2.30604 74.0779C1.79386 73.3617 1.28169 72.6455 0.669922 71.8029C2.12109 71.8029 3.42998 71.8872 4.73887 71.7748C5.90549 71.6765 6.55994 72.1119 7.20016 73.0387C10.0598 77.1955 12.9906 81.296 15.8929 85.4107C16.0067 85.5651 16.0779 85.7336 16.2201 85.9864Z" fill="#F0592A" />
      <path d="M10.3984 71.8158C12.1199 71.8158 13.7987 71.8298 15.4775 71.8018C15.8332 71.8018 15.9612 71.9984 16.132 72.2371C18.8636 76.0989 21.5952 79.9608 24.3268 83.8226C24.8105 84.4967 25.28 85.1848 25.8491 85.9853C23.9426 85.9853 22.1785 85.9853 20.4143 85.9853C20.2578 85.9853 20.1725 85.887 20.0871 85.7606C16.8575 81.1825 13.628 76.6185 10.4127 72.0405C10.3984 71.9984 10.4127 71.9422 10.3984 71.8158Z" fill="#30B489" />
    </Svg>
  )
}

export default GradientLogo
